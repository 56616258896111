import React, { FC } from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import SignupForm from 'containers/signupForm/sign-up';

const TechAndTrustWhitepaperSignup: FC< { location: string }> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Newsletter Sign Up" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero title={'ATB Ventures\'<br/> newsletter'} hero="trust" />
        <SignupForm
          title={'Sign up for the newsletter'}
          checkboxCopy="I agree to receiving ATB Ventures' newsletter."
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default TechAndTrustWhitepaperSignup;
