import React, { FC } from 'react';
import { Col, Container, Row } from 'components/ui/wrapper';
import Heading from 'components/ui/heading';
import Form, { Checkbox, FormGroup, Input, Label } from 'components/ui/form';
import Button from 'components/ui/button';
import Anchor from 'components/ui/anchor';
import parse from 'html-react-parser';
import { FormWrapper } from './sign-up.style';

interface Props {
  title: string;
  formGroupId?: string;
  headingStyle?: object;
  checkboxCopy?: string;
}

const SignupForm: FC<Props> = ({
  title,
  formGroupId,
  headingStyle,
  checkboxCopy = 'By downloading this whitepaper you are signing up for the ATB Ventures newsletter.',
}) => {
  return (
    <FormWrapper className="top-left-full-shadow-corner">
      <Container>
        <Row>
          <Col lg={10} className="mt-5 mt-lg-0 offset-lg-1">
            <>
              {title && <Heading {...headingStyle}>{parse(title) as string}</Heading>}
              <Form
                action="https://atbventures.us4.list-manage.com/subscribe/post"
                method="POST"
              >
                <input type="hidden" name="u" value="ba96a1c6f9fa269660f4104b4" />
                <input type="hidden" name="id" value="ba1454213b" />
                <Row>
                  <Col lg={6}>
                    <FormGroup mb="20px">
                      <Input
                        type="text"
                        name="NAME"
                        id="full_name"
                        placeholder="* Full Name"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup mb="20px">
                      <Input
                        type="email"
                        name="EMAIL"
                        id="email"
                        placeholder="* Email"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 3, order: 1 }} xs={{ span: 12, order: 3 }}>
                    <Button type="submit" hover="1">
                      Download
                    </Button>
                  </Col>
                  <Col md={{ span: 9, order: 2 }} xs={{ span: 12, order: 1 }}>
                    <Label>
                      <Checkbox name="group[67388][1]" required />
                      {checkboxCopy} You may unsubscribe at any time. Please refer
                      to the&nbsp;
                      <Anchor
                        color="primary"
                        path={'/privacy-policy'}
                        target={'_blank'}
                      >
                        Privacy Policy
                      </Anchor>
                      &nbsp;for more details.
                    </Label>
                  </Col>
                </Row>
                {formGroupId && (
                  <input
                    hidden={true}
                    type="checkbox"
                    name={formGroupId}
                    checked={true}
                  />
                )}
                {/* Anti-spam field for MailChimp */}
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_ba96a1c6f9fa269660f4104b4_7eac8ab38d"
                    tabIndex={-1}
                    defaultValue=""
                  />
                </div>
              </Form>
            </>
          </Col>
        </Row>
      </Container>
    </FormWrapper>
  );
};

SignupForm.defaultProps = {
  headingStyle: {
    as: 'h3',
    mb: '30px',
    textalign: 'center',
  },
};

export default SignupForm;
